import React from 'react';

const AvatarProfile = ({ name }) => {
  return (
    <span key={name} style={{ display: 'flex', alignItems: 'center' }}>
      <img src="/images/avatar-pic.svg" alt={name} style={{ display: 'inline-block', marginRight: '0.5rem' }} />
      {name}
    </span>
  );
};

export default AvatarProfile;

import React, { useState, useEffect } from "react";

import { ThemeProvider } from "@emotion/react";
import { legionTheme } from "@legion-ui/core";
import * as styles from "./CardColor.module.scss";

const calculateOppositeColor = (hexColor) => {
  const hexWithoutHash = hexColor.replace("#", "");
  const red = parseInt(hexWithoutHash.slice(0, 2), 16);
  const green = parseInt(hexWithoutHash.slice(2, 4), 16);
  const blue = parseInt(hexWithoutHash.slice(4, 6), 16);

  // Calculate the brightness using the formula: Y = 0.299*R + 0.587*G + 0.114*B
  const brightness = (0.299 * red + 0.587 * green + 0.114 * blue) / 255;

  // Determine the opposite color based on the brightness
  return brightness > 0.5 ? "#000000" : "#ffffff";
};

const ColorsCard = ({ variant }) => {
  const [color, setColor] = useState(legionTheme.tokens[variant]);
  const [oppositeColor, setOppositeColor] = useState(
    calculateOppositeColor(color)
  );

  useEffect(() => {
    setColor(legionTheme.tokens[variant]);
    setOppositeColor(calculateOppositeColor(legionTheme.tokens[variant]));
  }, [variant]);

  return (
    <ThemeProvider theme={legionTheme}>
      <div className={styles.card}>
        <div
          className={styles.colorBlock}
          style={{
            backgroundColor: color,
            color: oppositeColor,
          }}
        >
          {color}
        </div>
        <div>
          <small>{variant}</small>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default ColorsCard;

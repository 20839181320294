import React from "react";
import loadable from "@loadable/component";

const OtherComponent = loadable(() => import("react-apexcharts"));

const ApexChart = ({ options, series, type, width, height }) => {
  return (
    <OtherComponent
      options={options}
      series={series}
      type={type}
      width={436}
      height={229}
    />
  );
};

export default ApexChart;

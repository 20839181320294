import React, { useState, useRef, useEffect } from "react";
import { IconExternalLink } from "@tabler/icons-react";
import { breakpointsButton } from "./PatternIframe.module.scss";

// Breakpoints for the iframe
const BREAKPOINTS = [
  {
    type: "sm",
    width: 360,
    height: 640,
  },
  {
    type: "md",
    width: 1024,
    height: 768,
  },
  {
    type: "lg",
    width: 1440,
    height: 900,
  },
  {
    type: "xl",
    width: 1920,
    height: 1080,
  },
];

const PatternIframe = ({ url, breakpoints = ["sm", "md", "lg", "xl"] }) => {
  const [currBreakpoint, setCurrBreakpoint] = useState({
    width: 1440,
    height: 900,
  });
  const [iframeScale, setIframeScale] = useState(null);
  const [iframeInnerHeight, setIframeInnerHeight] = useState(640);

  const containerRef = useRef(null);
  const iframeRef = useRef(null);

  // this useEffect calculates the amount of scale that should be applied on the iframe
  // scale = clientWidth / iframeWidth
  useEffect(() => {
    if (currBreakpoint.width === 360) {
      setIframeScale(1);
    } else {
      setIframeScale(
        (containerRef?.current?.clientWidth - 16) / currBreakpoint.width
      );
    }

    const iframeHeight = iframeRef?.current?.getBoundingClientRect()?.height;

    setIframeInnerHeight(+iframeHeight + 16);
  }, [
    currBreakpoint.width,
    containerRef?.current?.clientWidth,
    iframeRef?.current?.getBoundingClientRect().height,
  ]);

  // handles breakpoint changes
  const handleChangeCurrWidth = (breakpoint) => {
    setCurrBreakpoint(breakpoint);
  };

  return (
    <div
      style={{
        backgroundColor: "#D8DAE5",
        width: "100%",
        height: "100%",
        overflow: "hidden",
      }}
    >
      <div style={{ display: "flex" }}>
        {BREAKPOINTS.map((breakpoint) => (
          <button
            disabled={breakpoints.includes(breakpoint.type) ? false : true}
            key={breakpoint.width}
            className={breakpointsButton}
            style={{
              backgroundColor: `${
                currBreakpoint.width === breakpoint.width
                  ? "transparent"
                  : "#B8BAC3"
              }`,
              cursor: `${
                breakpoints.includes(breakpoint.type) ? "pointer" : "default"
              }`,
            }}
            onClick={() => handleChangeCurrWidth(breakpoint)}
          >
            {breakpoint.width}x{breakpoint.height}
          </button>
        ))}
      </div>
      <div
        ref={containerRef}
        style={{
          padding: ".5rem",
          width: "100%",
          height: `${iframeInnerHeight}px`,
        }}
      >
        <iframe
          ref={iframeRef}
          src={url}
          frameborder="0"
          width={currBreakpoint.width}
          height={currBreakpoint.height}
          style={{
            transform: `scale(${iframeScale})`,
            transformOrigin: "0 0",
            backgroundColor: "white",
          }}
        ></iframe>
      </div>
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          backgroundColor: "#F2F4FF",
          width: "100%",
          padding: "1rem 1.5rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          color: "#667085",
          textDecoration: "none",
          fontWeight: "bold",
        }}
      >
        Live Preview <IconExternalLink />
      </a>
    </div>
  );
};

export default PatternIframe;
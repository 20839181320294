import React, { useState, useEffect } from "react";

export const themeContext = React.createContext();

const Provider = (props) => {
  const [currentTheme, setCurrentTheme] = useState("Legion");
  const [currentFonts, setCurrentFonts] = useState({
    label: "",
    heading: "",
    body: "",
    url: "",
  });

  useEffect(() => {
    // Get stored value
    const savedTheme = JSON.parse(localStorage.getItem("theme"));
    const savedFonts = JSON.parse(localStorage.getItem("fonts"));

    if (savedTheme) {
      setCurrentTheme(savedTheme.value);
    } else {
      setCurrentTheme("Legion");
    }

    if (savedFonts) {
      setCurrentFonts({
        label: savedFonts.label,
        heading: savedFonts.heading,
        body: savedFonts.body,
        bodySmall: savedFonts.bodySmall,
        url: savedFonts.url,
      });
    } else {
      setCurrentFonts({
        label: "Default Theme",
        heading: "Nunito Sans",
        body: "Nunito Sans",
        url: '<link href="https://fonts.googleapis.com/css2?family=Nunito+Sans" rel="stylesheet">',
      });
    }
  }, []);

  const handleChangeCurrentTheme = ({
    label,
    value,
    heading,
    body,
    bodySmall,
    url,
  }) => {
    setCurrentTheme(value);
    setCurrentFonts({
      label: label,
      heading: heading,
      body: body,
      bodySmall: bodySmall,
      url: url,
    });
  };

  return (
    <themeContext.Provider
      value={{
        currentTheme,
        currentFonts,
        handleChangeCurrentTheme,
      }}
    >
      {props.children}
    </themeContext.Provider>
  );
};

export default ({ element }) => <Provider>{element}</Provider>;

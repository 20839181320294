import React, { useContext } from 'react';
import { custom_snippet, copy_button } from './FontFamily.module.css';

import { themeContext } from '../../provider';

const FontFamily = () => {
  const globalThemeContext = useContext(themeContext);

  const handleCopyUrlToClipboard = (url) => {
    // navigator.clipboard.writeText(url);
  };

  return (
    <div>
      <p>
        {globalThemeContext?.currentFonts?.label && (
          <span>
            Squad: <span style={{ fontWeight: 'bold' }}>{globalThemeContext?.currentFonts?.label}</span>{' '}
          </span>
        )}

        {globalThemeContext?.currentFonts?.heading && (
          <span>
            | Heading: <span style={{ fontWeight: 'bold' }}>{globalThemeContext?.currentFonts?.heading}</span> |
          </span>
        )}

        {globalThemeContext?.currentFonts?.body && globalThemeContext?.currentFonts?.bodySmall ? (
          <>
            <span>
              {' '}
              | Body Large: <span style={{ fontWeight: 'bold' }}>{globalThemeContext?.currentFonts?.body}</span>
            </span>

            <span>
              {' '}
              Body Small: <span style={{ fontWeight: 'bold' }}>{globalThemeContext?.currentFonts?.bodySmall}</span>
            </span>
          </>
        ) : (
          <span>
            {' '}
            Body: <span style={{ fontWeight: 'bold' }}>{globalThemeContext?.currentFonts?.body}</span>
          </span>
        )}
      </p>

      <br />

      {globalThemeContext?.currentFonts?.url && (
        <div style={{ width: '100%', position: 'relative' }}>
          <pre className={custom_snippet}>
            <code>{globalThemeContext.currentFonts.url}</code>
          </pre>

          <img
            onClick={() => handleCopyUrlToClipboard(globalThemeContext.currentFonts.url)}
            className={copy_button}
            src="/images/copy.svg"
            alt="copy button"
          />
        </div>
      )}
    </div>
  );
};

export default FontFamily;

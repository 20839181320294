import React from "react";
import { navigate } from "gatsby";
import { Accordion, legionTheme as defaultTheme, Modal } from "@legion-ui/core";
import { ThemeProvider } from "@emotion/react";
import { Button, Tooltip } from "@legion-ui/core";
import { IconCircleCheck } from "@tabler/icons-react";

import * as styles from "./ChecklistResourcesSection.module.scss";

const ChecklistComponent = ({ checklist }) => {
  return (
    <>
      {checklist?.map((checklist) => (
        <span className={styles.checklistContainer}>
          <img
            src="/images/circle-check.svg"
            alt="check-list-icon"
            width="22px"
            height="22px"
          />{" "}
          {checklist.text}
        </span>
      ))}
    </>
  );
};

const generateAccordionItem = (title, checklist) => ({
  title,
  content: (
    <div className={styles.accordionContent}>
      <ChecklistComponent checklist={checklist} />
    </div>
  ),
});

const ChecklistResourcesSection = ({ figmaUrl, codeUrl, checklists }) => {
  const [show, setShow] = React.useState(false);

  const redirect = (url) => {
    navigate(url);
  };

  const accordionItems = [
    generateAccordionItem(
      "Accessibility Standard",
      checklists?.accessibilityStandard
    ),
    generateAccordionItem(
      "Structure & Properties",
      checklists?.structureProperties
    ),
    generateAccordionItem(
      "Design Implementation Test",
      checklists?.designImplementationTest
    ),
    generateAccordionItem(
      "Guideline Component",
      checklists?.guidelineComponent
    ),
    // generateAccordionItem("Asset Code Standard", checklists?.assetCodeStandard),
  ];

  return (
    <ThemeProvider theme={defaultTheme}>
      <Modal
        data-testid="modal_id"
        show={show}
        onClose={() => setShow(false)}
        title="Component Status Details"
        width="94%"
        className={styles.modalChecklist}
      >
        <p style={{ marginBottom: "24px" }}>
          Status component contains a list of checks and completeness that has
          been tested and owned by each component
        </p>

        <Accordion items={accordionItems} />
      </Modal>

      <div className={styles.buttonSection}>
        <div>
          <p
            style={{ marginBottom: "12px", fontSize: "14px", fontWeight: 400 }}
          >
            Status
          </p>

          <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            <Button
              color="tertiary"
              size="md"
              variant="soft"
              disabled={true}
              className={styles.buttonComplete}
              iconLeft={<IconCircleCheck size={22} />}
            >
              Completely Tested
            </Button>

            {checklists === "#" ? null : (
              <Button
                color="tertiary"
                size="md"
                variant="soft"
                onClick={() => setShow(true)}
              >
                View Checklist
              </Button>
            )}
          </div>
        </div>

        <div>
          <p
            style={{ marginBottom: "12px", fontSize: "14px", fontWeight: 400 }}
          >
            Resources
          </p>
          <div style={{ display: "flex", gap: "1rem" }}>
            {figmaUrl !== "#" && (
              <>
                <Button
                  color="tertiary"
                  size="md"
                  variant="soft"
                  iconLeft={
                    <img
                      src="/images/figma.svg"
                      alt="figma logo"
                      style={{ width: "22px", height: "22px" }}
                    />
                  }
                  onClick={() => redirect(figmaUrl)}
                >
                  View Design{" "}
                </Button>
              </>
            )}

            {figmaUrl === "#" && (
              <Tooltip text="Upcoming">
                <Button
                  color="tertiary"
                  size="md"
                  variant="soft"
                  disabled={true}
                  iconLeft={
                    <img
                      src="/images/figma.svg"
                      alt="figma logo"
                      style={{ width: "22px", height: "22px" }}
                    />
                  }
                  onClick={() => redirect(figmaUrl)}
                  className={styles.disabledButton}
                >
                  View Design{" "}
                </Button>
              </Tooltip>
            )}

            {codeUrl !== "#" && (
              <>
                <Button
                  color="tertiary"
                  size="md"
                  variant="soft"
                  iconLeft={
                    <img
                      src="/images/gitlab.svg"
                      alt="figma logo"
                      style={{ width: "22px", height: "22px" }}
                    />
                  }
                  onClick={() => redirect(codeUrl)}
                >
                  View Code{" "}
                </Button>
              </>
            )}

            {codeUrl === "#" && (
              <Tooltip text="Upcoming">
                <Button
                  color="tertiary"
                  size="md"
                  variant="soft"
                  disabled={true}
                  iconLeft={
                    <img
                      src="/images/gitlab.svg"
                      alt="figma logo"
                      style={{ width: "22px", height: "22px" }}
                    />
                  }
                  onClick={() => redirect(codeUrl)}
                  className={styles.disabledButton}
                >
                  View Code{" "}
                </Button>
              </Tooltip>
            )}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};

export default ChecklistResourcesSection;

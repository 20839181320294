import React from "react";
import PropTypes from "prop-types";
import * as styles from "./FigmaEmbed.module.scss";

const FigmaEmbed = ({ figmaSrc, imageSrc }) => {
  return (
    <article className={styles.wrapper}>
      <img src={imageSrc} />
      <a target="_blank" href={figmaSrc} className={styles.text}>See More Blocks</a>
    </article>
  );
};

FigmaEmbed.propTypes = {
  figmaSrc: PropTypes.string.isRequired,
  imageSrc: PropTypes.string.isRequired,
};

export default FigmaEmbed;

import React, { useState, useRef, useContext } from "react";
import IframeResizer from "iframe-resizer-react";
import { Spinner } from "@legion-ui/core";

import { themeContext } from "../../provider";

const StorybookEmbed = ({ storybookId }) => {
  const globalThemeContext = useContext(themeContext);
  const iframeRef = useRef(null);

  const [isIframeLoaded, setIsIframeLoaded] = useState(true);
  const [messageData, setMessageData] = useState();

  const onResized = (data) => setMessageData(data);

  const onMessage = (data) => {
    setMessageData(data);
    iframeRef.current.sendMessage("Hello back from the parent page");
  };

  const handleOnLoad = () => {
    setIsIframeLoaded(false);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: isIframeLoaded ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "2rem",
        }}
      >
        <Spinner />
      </div>
      <div
        style={{
          height: "max-content",
          width: "calc(100% + 40px)",
          marginLeft: "-20px",
          marginTop: "-32px",
        }}
      >
        <IframeResizer
          bodyPadding="0px"
          log
          inPageLinks
          forwardRef={iframeRef}
          onMessage={onMessage}
          onResized={onResized}
          onLoad={handleOnLoad}
          autoResize
          heightCalculationMethod="lowestElement"
          style={{ width: "1px", minWidth: "100%" }}
          src={`https://master--6420e4161e2acdd49d1d3e4b.chromatic.com/iframe.html?id=${storybookId}&viewMode=docs&shortcuts=false&singlestory=true&globals=theme:${globalThemeContext?.currentTheme}`}
        />
      </div>
    </>
  );
};

export default StorybookEmbed;

import { Button, Chip } from "@legion-ui/core";
import Lottie from "react-lottie-player";
import { IconArrowRight } from "@tabler/icons-react";
import lottieJson from "../../../../src/pages/Landingnew2.json";
import lottieJsonTwo from "../../../../src/pages/lottie_json_two.json";
import * as React from 'react';
export default {
  Button,
  Chip,
  Lottie,
  IconArrowRight,
  lottieJson,
  lottieJsonTwo,
  React
};
export const CHARTS = {
  bar: {
    options: {
      annotations: {},
      title: {
        text: "1. Bar Chart",
        align: "left",
        margin: 4,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "18px",
          fontWeight: "bold",
          fontFamily: "Nunito Sans",
          color: "#333333",
        },
      },
      chart: {
        animations: {
          enabled: false,
          easing: "swing",
        },
        foreColor: "#212121",
        fontFamily: "Nunito Sans",
        height: 230,
        id: "73IFh",
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          autoSelected: "zoom",
        },
        type: "bar",
        width: 400,
        fontUrl: null,
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "50%",
          borderRadius: 4,
          borderRadiusApplication: "end",
          borderRadiusWhenStacked: "last",
          hideZeroBarsWhenGrouped: false,
          isDumbbell: false,
          isFunnel: false,
          isFunnel3d: true,
          dataLabels: {
            total: {
              enabled: false,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: "#373d3f",
                fontSize: "12px",
                fontWeight: 600,
              },
            },
          },
        },
        bubble: {
          zScaling: true,
        },
        treemap: {
          dataLabels: {
            format: "scale",
          },
        },
        radialBar: {
          hollow: {
            background: "#fff",
          },
          dataLabels: {
            name: {},
            value: {},
            total: {},
          },
        },
        pie: {
          donut: {
            labels: {
              name: {},
              value: {},
              total: {},
            },
          },
        },
      },
      colors: ["#528BFF", "#c7f464", "#81D4FA", "#fd6a6a", "#546E7A"],
      dataLabels: {
        enabled: false,
        offsetX: -2,
        offsetY: -3,
        style: {
          fontWeight: 700,
          colors: ["#fff"],
        },
        background: {
          enabled: false,
        },
      },
      fill: {
        opacity: 1,
      },
      grid: {
        padding: {
          right: 25,
          left: 15,
        },
      },
      legend: {
        fontSize: 14,
        offsetY: 0,
        markers: {
          shape: "square",
          size: 8,
        },
        itemMargin: {
          vertical: 0,
        },
      },
      series: [
        {
          name: "Users",
          data: [
            {
              x: "Iphone",
              y: "160",
            },
            {
              x: "Samsung",
              y: "100",
            },
            {
              x: "Oppo",
              y: "50",
            },
          ],
        },
      ],
      stroke: {
        show: false,
        fill: {
          type: "solid",
          opacity: 0.85,
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 100],
            colorStops: [],
          },
        },
      },
      tooltip: {
        enabled: true,
        shared: false,
        intersect: true,
      },
      xaxis: {
        tickPlacement: "on",
        labels: {
          trim: true,
          style: {},
        },
        group: {
          groups: [],
          style: {
            colors: [],
            fontSize: "12px",
            fontWeight: 400,
            cssClass: "",
          },
        },
        title: {
          text: "Number of users",
          style: {
            fontWeight: 800,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        tickAmount: 5,
        labels: {
          offsetX: 4,
          style: {},
        },
        title: {
          text: "Type of device",
          style: {
            fontWeight: 800,
          },
        },
      },
    },
  },
  column: {
    options: {
      annotations: {},
      title: {
        text: "2. Column Chart",
        align: "left",
        margin: 4,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "18px",
          fontWeight: "bold",
          fontFamily: "Nunito Sans",
          color: "#333333",
        },
      },
      chart: {
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          autoSelected: "zoom",
        },
        animations: {
          enabled: false,
          easing: "swing",
        },
        foreColor: "#333",
        fontFamily: "Nunito Sans",
        height: 250,
        id: "8KfCj",
        type: "bar",
        width: 400,
        fontUrl: null,
      },
      plotOptions: {
        bar: {
          borderRadius: 2,
          borderRadiusApplication: "end",
          borderRadiusWhenStacked: "last",
          hideZeroBarsWhenGrouped: false,
          isDumbbell: false,
          isFunnel: false,
          isFunnel3d: true,
          dataLabels: {
            total: {
              enabled: false,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: "#373d3f",
                fontSize: "12px",
                fontWeight: 600,
              },
            },
          },
        },
        bubble: {
          zScaling: true,
        },
        treemap: {
          dataLabels: {
            format: "scale",
          },
        },
        radialBar: {
          hollow: {
            background: "#fff",
          },
          dataLabels: {
            name: {},
            value: {},
            total: {},
          },
        },
        pie: {
          donut: {
            labels: {
              name: {},
              value: {},
              total: {},
            },
          },
        },
      },
      colors: ["#528BFF", "#32D583", "#81D4FA", "#fd6a6a", "#546E7A"],
      dataLabels: {
        enabled: false,
        style: {
          fontWeight: 700,
        },
      },
      fill: {
        opacity: 1,
      },
      grid: {
        padding: {
          right: 25,
          left: 15,
        },
      },
      legend: {
        offsetY: 0,
        markers: {
          shape: "square",
          size: 8,
        },
        itemMargin: {
          horizontal: 12,
          vertical: 8,
        },
      },
      series: [
        {
          name: "Monthly",
          data: [
            {
              x: "Jan",
              y: 10,
            },
            {
              x: "Feb",
              y: 20,
            },
            {
              x: "Mar",
              y: 30,
            },
            {
              x: "Apr",
              y: 40,
            },
            {
              x: "May",
              y: "30",
            },
            {
              x: "Jun",
              y: "40",
            },
            {
              x: "Jul",
              y: "60",
            },
            {
              x: "Aug",
              y: "30",
            },
            {
              x: "Sep",
              y: "50",
            },
          ],
        },
        {
          name: "Annualy",
          data: [
            {
              x: "Jan",
              y: "30",
            },
            {
              x: "Feb",
              y: "40",
            },
            {
              x: "Mar",
              y: "50",
            },
            {
              x: "Apr",
              y: "60",
            },
            {
              x: "May",
              y: "40",
            },
            {
              x: "Jun",
              y: "50",
            },
            {
              x: "Jul",
              y: "50",
            },
            {
              x: "Aug",
              y: "40",
            },
            {
              x: "Sep",
              y: "30",
            },
          ],
        },
      ],
      stroke: {
        show: false,
        width: 0,
        fill: {
          type: "solid",
          opacity: 0.85,
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 100],
            colorStops: [],
          },
        },
      },
      tooltip: {
        shared: false,
        intersect: true,
      },
      xaxis: {
        labels: {
          trim: true,
          style: {},
        },
        group: {
          groups: [],
          style: {
            colors: [],
            fontSize: "12px",
            fontWeight: 400,
            cssClass: "",
          },
        },
        tickPlacement: "on",
        title: {
          text: "",
          style: {
            fontWeight: 700,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        tickAmount: 5,
        labels: {
          style: {},
        },
        title: {
          text: "Number of subscription",
          style: {
            fontWeight: 800,
          },
        },
      },
      theme: {
        palette: "palette4",
      },
    },
  },
  area: {
    options: {
      series: [
        {
          name: "Area",
          data: [
            {
              x: "Jan",
              y: 31,
            },
            {
              x: "Feb",
              y: 40,
            },
            {
              x: "Mar",
              y: 28,
            },
            {
              x: "Apr",
              y: 51,
            },
            {
              x: "May",
              y: 42,
            },
            {
              x: "Jun",
              y: 56,
            },
            {
              x: "Jul",
              y: 33,
            },
          ],
        },
      ],
      annotations: {
        position: "front",
        yaxis: [],
        xaxis: [],
        points: [],
      },
      title: {
        text: "3. Area Chart",
        align: "left",
        margin: 4,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "18px",
          fontWeight: "bold",
          fontFamily: "Nunito Sans",
          color: "#333333",
        },
      },
      chart: {
        type: "area",
        background: "transparent",
        foreColor: "#333",
        offsetX: 0,
        offsetY: 0,
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          autoSelected: "zoom",
        },
        animations: {
          enabled: false,
        },
        dropShadow: {
          enabled: false,
          top: 2,
          left: 2,
          blur: 4,
          color: "#000",
          opacity: 0.35,
        },
        fontFamily: "Nunito Sans",
        height: 228,
        width: 414,
        id: "bL5Y8",
        fontUrl: null,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "70%",
          barHeight: "70%",
          distributed: false,
          borderRadius: 10,
          borderRadiusApplication: "end",
          borderRadiusWhenStacked: "last",
          colors: {
            ranges: [],
            backgroundBarColors: [],
            backgroundBarOpacity: 1,
          },
          dataLabels: {
            position: "top",
          },
        },
        heatmap: {
          radius: 2,
          enableShades: true,
          shadeIntensity: 0.5,
        },
        radialBar: {
          startAngle: 0,
          endAngle: 360,
          offsetX: 0,
          offsetY: 0,
          hollow: {
            margin: 5,
            size: "50%",
            background: "#fff",
            position: "front",
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 3,
              color: "#000",
              opacity: 0.5,
            },
          },
          track: {
            show: true,
            background: "#f2f2f2",
            strokeWidth: "97%",
            opacity: 1,
            margin: 5,
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 3,
              color: "#000",
              opacity: 0.5,
            },
          },
          dataLabels: {
            show: true,
            name: {
              show: true,
              fontSize: 16,
              offsetY: 0,
            },
            value: {
              show: true,
              fontSize: 14,
              offsetY: 16,
            },
            total: {
              show: false,
              label: "Total",
              fontSize: 16,
            },
          },
        },
        pie: {
          startAngle: 0,
          endAngle: 360,
          offsetX: 0,
          offsetY: 0,
          dataLabels: {
            offset: 0,
          },
          donut: {
            size: "65%",
            labels: {
              show: false,
              name: {
                show: true,
                fontSize: 16,
                offsetY: -10,
              },
              value: {
                show: true,
                fontSize: 20,
                offsetY: 10,
              },
              total: {
                show: false,
                showAlways: false,
                label: "Total",
                fontSize: 16,
              },
            },
          },
        },
        radar: {
          offsetX: 0,
          offsetY: 0,
          polygons: {
            strokeColors: "#e8e8e8",
            connectorColors: "#e8e8e8",
            fill: {},
          },
        },
      },
      colors: ["#2970FF", "#c7f464", "#81D4FA", "#fd6a6a", "#546E7A"],
      theme: {
        mode: "light",
        palette: "palette4",
      },
      dataLabels: {
        enabled: false,
        textAnchor: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: 12,
          fontWeight: 700,
        },
        background: {
          enabled: true,
          foreColor: "#fff",
          borderRadius: 2,
          padding: 4,
          opacity: 0.9,
          borderWidth: 1,
          borderColor: "#fff",
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
      markers: {
        size: 0,
        strokeColors: "#fff",
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        hover: {},
      },
      xaxis: {
        type: "numeric",
        offsetX: 0,
        offsetY: 0,
        position: "bottom",
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: false,
          trim: true,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: 12,
            fontWeight: 400,
            cssClass: "",
          },
          hideOverlappingLabels: true,
          maxHeight: 120,
          showDuplicates: true,
          datetimeUTC: true,
          datetimeFormatter: {
            year: "yyyy",
            month: "MMM 'yy",
            day: "dd MMM",
            hour: "HH:mm",
            minute: "HH:mm:ss",
            second: "HH:mm:ss",
          },
        },
        axisBorder: {
          show: true,
          color: "#e0e0e0",
          width: "100%",
          height: 1,
          offsetX: 0,
          offsetY: 0,
        },
        axisTicks: {
          show: true,
          color: "#e0e0e0",
          height: 6,
          offsetX: 0,
          offsetY: 0,
        },
        title: {
          style: {
            fontSize: 12,
            fontWeight: 700,
            cssClass: "",
          },
          offsetX: 0,
          offsetY: 0,
        },
        crosshairs: {
          show: true,
          width: 1,
          position: "back",
          opacity: 0.9,
          stroke: {
            color: "#b6b6b6",
            width: 1,
            dashArray: 3,
          },
          fill: {
            type: "solid",
            color: "#B1B9C4",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              opacityFrom: 0.4,
              opacityTo: 0.5,
              stops: [0, 100],
            },
          },
          dropShadow: {
            enabled: false,
            left: 0,
            top: 0,
            blur: 1,
            opacity: 0.4,
          },
        },
        categories: [],
        convertedCatToNumeric: false,
        group: {
          groups: [],
          style: {
            colors: [],
            fontSize: "12px",
            fontWeight: 400,
            cssClass: "",
          },
        },
        tickAmount: "dataPoints",
        tickPlacement: "on",
        floating: false,
        tooltip: {
          enabled: true,
          offsetY: 0,
          style: {
            fontSize: "12px",
          },
        },
      },
      yaxis: [
        {
          show: true,
          showAlways: false,
          showForNullSeries: true,
          opposite: false,
          reversed: false,
          logarithmic: false,
          logBase: 10,
          tickAmount: 5,
          forceNiceScale: false,
          floating: false,
          labels: {
            show: true,
            minWidth: 0,
            maxWidth: 160,
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            padding: 20,
            style: {
              fontSize: 11,
              fontWeight: 400,
              cssClass: "",
            },
          },
          axisBorder: {
            show: false,
            color: "#e0e0e0",
            width: 1,
            offsetX: 0,
            offsetY: 0,
          },
          axisTicks: {
            show: false,
            color: "#e0e0e0",
            width: 6,
            offsetX: 0,
            offsetY: 0,
          },
          title: {
            text: "Number of visitor",
            rotate: -90,
            offsetY: 0,
            offsetX: 0,
            style: {
              fontSize: 11,
              fontWeight: 700,
              cssClass: "",
            },
          },
          tooltip: {
            enabled: false,
            offsetX: 0,
          },
          crosshairs: {
            show: true,
            position: "front",
            stroke: {
              color: "#b6b6b6",
              width: 1,
              dashArray: 0,
            },
          },
        },
      ],
      grid: {
        show: true,
        borderColor: "#e0e0e0",
        strokeDashArray: 0,
        position: "back",
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
        row: {
          opacity: 0.5,
        },
        column: {
          opacity: 0.5,
        },
        padding: {
          top: 0,
          right: 25,
          bottom: 0,
          left: 15,
        },
      },
      stroke: {
        show: true,
        curve: "straight",
        lineCap: "butt",
        width: 3,
        dashArray: 0,
      },
      fill: {
        type: "gradient",
        opacity: 1,
        gradient: {
          shade: "light",
          type: "vertical",
          shadeIntensity: 0,
          inverseColors: false,
          opacityFrom: 0.5,
          opacityTo: 0,
          stops: [0, 50, 100],
          colorStops: [
            {
              opacity: 0.5,
              offset: 0,
              color: "#008FFB",
            },
            {
              opacity: 0,
              offset: 100,
              color: "#008FFB",
            },
          ],
        },
        pattern: {
          style: "squares",
          width: 6,
          height: 6,
          strokeWidth: 2,
        },
      },
      legend: {
        show: true,
        showForSingleSeries: false,
        floating: false,
        position: "bottom",
        horizontalAlign: "center",
        fontSize: 14,
        fontWeight: 400,
        offsetX: -20,
        offsetY: 0,
        labels: {
          useSeriesColors: false,
        },
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          strokeColor: "#fff",
          radius: 12,
        },
        itemMargin: {
          horizontal: 5,
          vertical: 0,
        },
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
    },
  },
  spline: {
    options: {
      annotations: {},
      title: {
        text: "4. Spline Chart",
        align: "left",
        margin: 4,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "18px",
          fontWeight: "bold",
          fontFamily: "Nunito Sans",
          color: "#333333",
        },
      },
      chart: {
        animations: {
          enabled: false,
        },
        foreColor: "#333",
        fontFamily: "Nunito Sans",
        height: 229,
        id: "KX89h",
        stacked: true,
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          autoSelected: "zoom",
        },
        type: "area",
        width: 436,
        fontUrl: null,
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          borderRadiusApplication: "end",
          borderRadiusWhenStacked: "last",
          hideZeroBarsWhenGrouped: false,
          isDumbbell: false,
          isFunnel: false,
          isFunnel3d: true,
          dataLabels: {
            total: {
              enabled: false,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: "#373d3f",
                fontSize: "12px",
                fontWeight: 600,
              },
            },
          },
        },
        bubble: {
          zScaling: true,
        },
        treemap: {
          dataLabels: {
            format: "scale",
          },
        },
        radialBar: {
          hollow: {
            background: "#fff",
          },
          dataLabels: {
            name: {},
            value: {},
            total: {},
          },
        },
        pie: {
          donut: {
            labels: {
              name: {},
              value: {},
              total: {},
            },
          },
        },
      },
      colors: ["#FDB022", "#32D583", "#32D583", "#fd6a6a", "#546E7A"],
      dataLabels: {
        enabled: false,
        style: {
          fontWeight: 700,
        },
      },
      fill: {
        type: "gradient",
        opacity: 1,
        gradient: {
          shade: "light",
          type: "vertical",
          inverseColors: false,
          opacityFrom: 0.8,
          opacityTo: 0.5,
        },
      },
      grid: {
        padding: {
          right: 36,
          left: 15,
        },
      },
      legend: {
        offsetY: 0,
        itemMargin: {
          horizontal: 12,
          vertical: 8,
        },
      },
      markers: {
        hover: {
          sizeOffset: 6,
        },
      },
      series: [
        {
          name: "In Progress",
          data: [
            {
              x: "Jan",
              y: 20,
            },
            {
              x: "Feb",
              y: 32,
            },
            {
              x: "Mar",
              y: 38,
            },
            {
              x: "Apr",
              y: 22,
            },
            {
              x: "May",
              y: 56,
            },
            {
              x: "Jun",
              y: "55",
            },
            {
              x: "Jul",
              y: "65",
            },
          ],
        },
        {
          name: "Done",
          data: [
            {
              x: "Jan",
              y: "35",
            },
            {
              x: "Feb",
              y: "40",
            },
            {
              x: "Mar",
              y: "30",
            },
            {
              x: "Apr",
              y: "50",
            },
            {
              x: "May",
              y: "60",
            },
            {
              x: "Jun",
              y: "65",
            },
            {
              x: "Jul",
              y: "70",
            },
          ],
        },
      ],
      stroke: {
        lineCap: "round",
        width: 3,
        fill: {
          type: "solid",
          opacity: 0.85,
          gradient: {
            shade: "light",
            type: "vertical",
            shadeIntensity: 0.5,
            inverseColors: false,
            opacityFrom: 0.65,
            opacityTo: 0.5,
            stops: [0, 100, 100],
            colorStops: [],
          },
        },
      },
      xaxis: {
        labels: {
          trim: true,
          style: {},
        },
        group: {
          groups: [],
          style: {
            colors: [],
            fontSize: "12px",
            fontWeight: 400,
            cssClass: "",
          },
        },
        tickPlacement: "on",
        tickAmount: "dataPoints",
        title: {
          style: {
            fontWeight: 700,
          },
        },
      },
      yaxis: {
        labels: {
          style: {},
        },
        title: {
          style: {
            fontWeight: 700,
          },
        },
      },
      theme: {
        palette: "palette4",
      },
    },
  },
  line: {
    options: {
      annotations: {},
      title: {
        text: "5. Line Chart",
        align: "left",
        margin: 4,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "18px",
          fontWeight: "bold",
          fontFamily: "Nunito Sans",
          color: "#333333",
        },
      },
      chart: {
        animations: {
          enabled: false,
          easing: "swing",
        },
        foreColor: "#333",
        fontFamily: "Nunito Sans",
        height: 258,
        id: "2xEuH",
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          autoSelected: "zoom",
        },
        width: 415,
        fontUrl: null,
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          borderRadiusApplication: "end",
          borderRadiusWhenStacked: "last",
          hideZeroBarsWhenGrouped: false,
          isDumbbell: false,
          isFunnel: false,
          isFunnel3d: true,
          dataLabels: {
            total: {
              enabled: false,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: "#373d3f",
                fontSize: "12px",
                fontWeight: 600,
              },
            },
          },
        },
        bubble: {
          zScaling: true,
        },
        treemap: {
          dataLabels: {
            format: "scale",
          },
        },
        radialBar: {
          hollow: {
            background: "#fff",
          },
          dataLabels: {
            name: {},
            value: {},
            total: {},
          },
        },
        pie: {
          donut: {
            labels: {
              name: {},
              value: {},
              total: {},
            },
          },
        },
      },
      colors: ["#875BF7", "#c7f464", "#81D4FA", "#fd6a6a", "#546E7A"],
      dataLabels: {
        enabled: false,
        style: {
          fontWeight: 700,
        },
      },
      fill: {
        opacity: 1,
      },
      grid: {
        padding: {
          right: 25,
          left: 15,
        },
      },
      legend: {
        offsetY: 0,
        itemMargin: {
          horizontal: 12,
          vertical: 8,
        },
      },
      markers: {
        hover: {
          size: 0,
          sizeOffset: 6,
        },
      },
      series: [
        {
          name: "Pertamax",
          data: [
            {
              x: "Jan",
              y: "35",
            },
            {
              x: "Feb",
              y: 40,
            },
            {
              x: "Mar",
              y: "30",
            },
            {
              x: "Apr",
              y: 51,
            },
            {
              x: "May",
              y: 42,
            },
            {
              x: "Jun",
              y: "60",
            },
            {
              x: "Jul",
              y: "80",
            },
          ],
        },
      ],
      stroke: {
        curve: "straight",
        lineCap: "round",
        width: 3,
        fill: {
          type: "solid",
          opacity: 0.85,
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 100],
            colorStops: [],
          },
        },
      },
      xaxis: {
        labels: {
          trim: true,
          style: {},
        },
        group: {
          groups: [],
          style: {
            colors: [],
            fontSize: "12px",
            fontWeight: 400,
            cssClass: "",
          },
        },
        tickPlacement: "on",
        tickAmount: "dataPoints",
        title: {
          style: {
            fontWeight: 700,
          },
        },
      },
      yaxis: {
        labels: {
          style: {},
        },
        title: {
          style: {
            fontWeight: 700,
          },
        },
      },
      theme: {
        palette: "palette4",
      },
    },
  },
  multiLine: {
    options: {
      annotations: {},
      title: {
        text: "6. Multi Line Chart",
        align: "left",
        margin: 4,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "18px",
          fontWeight: "bold",
          fontFamily: "Nunito Sans",
          color: "#333333",
        },
      },
      chart: {
        animations: {
          enabled: false,
          easing: "swing",
        },
        foreColor: "#333",
        fontFamily: "Nunito Sans",
        height: 231,
        id: "SROoo",
        toolbar: {
          show: false,
          tools: {
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
          },
        },
        width: 415,
        fontUrl: null,
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          borderRadiusApplication: "end",
          borderRadiusWhenStacked: "last",
          hideZeroBarsWhenGrouped: false,
          isDumbbell: false,
          isFunnel: false,
          isFunnel3d: true,
          dataLabels: {
            total: {
              enabled: false,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: "#373d3f",
                fontSize: "12px",
                fontWeight: 600,
              },
            },
          },
        },
        bubble: {
          zScaling: true,
        },
        treemap: {
          dataLabels: {
            format: "scale",
          },
        },
        radialBar: {
          hollow: {
            background: "#fff",
          },
          dataLabels: {
            name: {},
            value: {},
            total: {},
          },
        },
        pie: {
          donut: {
            labels: {
              name: {},
              value: {},
              total: {},
            },
          },
        },
      },
      colors: ["#2970FF", "#F79009", "#81D4FA", "#fd6a6a", "#546E7A"],
      dataLabels: {
        style: {
          fontWeight: 700,
        },
      },
      fill: {
        opacity: 1,
      },
      grid: {
        padding: {
          right: 25,
          left: 15,
        },
      },
      legend: {
        fontWeight: 300,
        offsetY: 0,
        itemMargin: {
          horizontal: 12,
          vertical: 8,
        },
      },
      markers: {
        hover: {
          size: 0,
          sizeOffset: 6,
        },
      },
      series: [
        {
          name: "Pertamax",
          data: [
            {
              x: "Jan",
              y: "35",
            },
            {
              x: "Feb",
              y: 40,
            },
            {
              x: "Mar",
              y: "30",
            },
            {
              x: "Apr",
              y: 51,
            },
            {
              x: "May",
              y: 42,
            },
            {
              x: "Jun",
              y: "60",
            },
            {
              x: "Jul",
              y: "80",
            },
          ],
        },
        {
          name: "Pertalite",
          data: [
            {
              x: "Jan",
              y: "70",
            },
            {
              x: "Feb",
              y: "80",
            },
            {
              x: "Mar",
              y: "60",
            },
            {
              x: "Apr",
              y: "90",
            },
            {
              x: "May",
              y: "85",
            },
            {
              x: "Jun",
              y: "100",
            },
            {
              x: "Jul",
              y: "140",
            },
          ],
        },
      ],
      stroke: {
        curve: "straight",
        lineCap: "round",
        width: 3,
        fill: {
          type: "solid",
          opacity: 0.85,
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 100],
            colorStops: [],
          },
        },
      },
      xaxis: {
        labels: {
          trim: true,
          style: {},
        },
        group: {
          groups: [],
          style: {
            colors: [],
            fontSize: "12px",
            fontWeight: 400,
            cssClass: "",
          },
        },
        tickAmount: "dataPoints",
        title: {
          style: {
            fontWeight: 700,
          },
        },
      },
      yaxis: {
        labels: {
          style: {},
        },
        title: {
          style: {
            fontWeight: 700,
          },
        },
      },
      theme: {
        palette: "palette4",
      },
    },
  },
  pie: {
    options: {
      annotations: {},
      title: {
        text: "1. Pie Chart",
        align: "left",
        margin: 4,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "18px",
          fontWeight: "bold",
          fontFamily: "Nunito Sans",
          color: "#333333",
        },
      },
      chart: {
        animations: {
          enabled: false,
        },
        foreColor: "#333",
        fontFamily: "Roboto",
        height: 288,
        id: "csy5d",
        type: "pie",
        width: 416,
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          autoSelected: "zoom",
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          borderRadiusApplication: "end",
          borderRadiusWhenStacked: "last",
          hideZeroBarsWhenGrouped: false,
          isDumbbell: false,
          isFunnel: false,
          isFunnel3d: true,
          dataLabels: {
            total: {
              enabled: false,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: "#373d3f",
                fontSize: "12px",
                fontWeight: 600,
              },
            },
          },
        },
        bubble: {
          zScaling: true,
        },
        treemap: {
          dataLabels: {
            format: "scale",
          },
        },
        radialBar: {
          hollow: {
            background: "#fff",
          },
          dataLabels: {
            name: {},
            value: {},
            total: {},
          },
        },
        pie: {
          donut: {
            labels: {
              name: {},
              value: {},
              total: {},
            },
          },
        },
      },
      colors: ["#2870FF", "#FDB122", "#0EB869", "#FF4560", "#775DD0"],
      dataLabels: {
        style: {
          fontWeight: 700,
        },
      },
      fill: {
        opacity: 1,
      },
      grid: {
        padding: {
          right: 0,
          bottom: 12,
          left: 10,
        },
      },
      labels: ["Age 20 - 39", "Age 40 - 59", "Age > 60"],
      legend: {
        position: "right",
        fontSize: 14,
        offsetY: 0,
        itemMargin: {
          vertical: 0,
        },
      },
      series: [25, 50, 25],
      stroke: {
        fill: {
          type: "solid",
          opacity: 0.85,
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 100],
            colorStops: [],
          },
        },
      },
      tooltip: {
        fillSeriesColor: true,
      },
      xaxis: {
        labels: {
          trim: true,
          style: {},
        },
        group: {
          groups: [],
          style: {
            colors: [],
            fontSize: "12px",
            fontWeight: 400,
            cssClass: "",
          },
        },
        tickPlacement: "on",
        title: {
          style: {
            fontWeight: 700,
          },
        },
      },
      yaxis: {
        labels: {
          style: {},
        },
        title: {
          style: {
            fontWeight: 700,
          },
        },
      },
    },
  },
  donut: {
    options: {
      series: [11, 24, 32],
      annotations: {
        position: "front",
        yaxis: [],
        xaxis: [],
        points: [],
      },
      title: {
        text: "2. Donut Chart",
        align: "left",
        margin: 4,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "18px",
          fontWeight: "bold",
          fontFamily: "Nunito Sans",
          color: "#333333",
        },
      },
      chart: {
        type: "donut",
        background: "transparent",
        foreColor: "#333",
        offsetX: 0,
        offsetY: 0,
        animations: {
          enabled: false,
        },
        dropShadow: {
          enabled: false,
          top: 2,
          left: 2,
          blur: 4,
          color: "#000",
          opacity: 0.35,
        },
        fontFamily: "Nunito Sans",
        height: 229,
        width: 354,
        id: "MfspR",
        fontUrl: null,
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          autoSelected: "zoom",
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "70%",
          barHeight: "70%",
          distributed: false,
          borderRadius: 10,
          borderRadiusApplication: "end",
          borderRadiusWhenStacked: "last",
          colors: {
            ranges: [],
            backgroundBarColors: [],
            backgroundBarOpacity: 1,
          },
          dataLabels: {
            position: "top",
          },
        },
        heatmap: {
          radius: 2,
          enableShades: true,
          shadeIntensity: 0.5,
        },
        radialBar: {
          startAngle: 0,
          endAngle: 360,
          offsetX: 0,
          offsetY: 0,
          hollow: {
            margin: 5,
            size: "50%",
            background: "#fff",
            position: "front",
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 3,
              color: "#000",
              opacity: 0.5,
            },
          },
          track: {
            show: true,
            background: "#f2f2f2",
            strokeWidth: "97%",
            opacity: 1,
            margin: 5,
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 3,
              color: "#000",
              opacity: 0.5,
            },
          },
          dataLabels: {
            show: true,
            name: {
              show: true,
              fontSize: 16,
              offsetY: 0,
            },
            value: {
              show: true,
              fontSize: 14,
              offsetY: 16,
            },
            total: {
              show: false,
              label: "Total",
              fontSize: 16,
            },
          },
        },
        pie: {
          startAngle: 0,
          endAngle: 360,
          offsetX: 0,
          offsetY: 0,
          dataLabels: {
            offset: 0,
          },
          donut: {
            size: "56%",
            labels: {
              show: false,
              name: {
                show: true,
                fontSize: 16,
                offsetY: -10,
                fontWeight: 600,
              },
              value: {
                show: true,
                fontSize: 20,
                offsetY: 10,
                fontWeight: 400,
              },
              total: {
                show: false,
                showAlways: false,
                label: "Total",
                fontSize: 16,
                fontWeight: 400,
              },
            },
          },
        },
        radar: {
          offsetX: 0,
          offsetY: 0,
          polygons: {
            strokeColors: "#e8e8e8",
            connectorColors: "#e8e8e8",
            fill: {},
          },
        },
      },
      colors: ["#12B76A", "#F79009", "#2970FF", "#fd6a6a", "#546E7A"],
      theme: {
        mode: "light",
        palette: "palette4",
      },
      dataLabels: {
        enabled: true,
        textAnchor: "middle",
        offsetX: 0,
        offsetY: 0,
        style: {
          fontSize: 12,
          fontWeight: 700,
        },
        background: {
          enabled: true,
          foreColor: "#fff",
          borderRadius: 2,
          padding: 0,
          opacity: 0,
          borderWidth: 0,
          borderColor: null,
        },
        dropShadow: {
          enabled: false,
          top: 1,
          left: 1,
          blur: 1,
          color: "#000",
          opacity: 0.45,
        },
      },
      markers: {
        size: 0,
        strokeColors: "#fff",
        strokeWidth: 2,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        hover: {},
      },
      xaxis: {
        tickPlacement: "on",
      },
      yaxis: [
        {
          show: true,
          showAlways: false,
          showForNullSeries: true,
          opposite: false,
          reversed: false,
          logarithmic: false,
          logBase: 10,
          forceNiceScale: false,
          floating: false,
          labels: {
            show: true,
            minWidth: 0,
            maxWidth: 160,
            offsetX: 0,
            offsetY: 0,
            rotate: 0,
            padding: 20,
            style: {
              fontSize: 11,
              fontWeight: 400,
              cssClass: "",
            },
          },
          axisBorder: {
            show: false,
            color: "#e0e0e0",
            width: 1,
            offsetX: 0,
            offsetY: 0,
          },
          axisTicks: {
            show: false,
            color: "#e0e0e0",
            width: 6,
            offsetX: 0,
            offsetY: 0,
          },
          title: {
            rotate: -90,
            offsetY: 0,
            offsetX: 0,
            style: {
              fontSize: 11,
              fontWeight: 700,
              cssClass: "",
            },
          },
          tooltip: {
            enabled: false,
            offsetX: 0,
          },
          crosshairs: {
            show: true,
            position: "front",
            stroke: {
              color: "#b6b6b6",
              width: 1,
              dashArray: 0,
            },
          },
        },
      ],
      grid: {
        show: true,
        borderColor: "#e0e0e0",
        strokeDashArray: 0,
        position: "back",
        padding: {
          top: 0,
          right: 0,
          bottom: 12,
          left: 10,
        },
      },
      stroke: {
        show: true,
        curve: "smooth",
        lineCap: "butt",
        width: 0,
        dashArray: 0,
      },
      fill: {
        type: "solid",
        opacity: 1,
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 100],
          colorStops: [],
        },
        pattern: {
          style: "squares",
          width: 6,
          height: 6,
          strokeWidth: 2,
        },
      },
      legend: {
        show: true,
        showForSingleSeries: false,
        floating: false,
        position: "right",
        horizontalAlign: "center",
        fontSize: 12,
        fontWeight: 400,
        offsetX: -16,
        offsetY: 0,
        labels: {
          useSeriesColors: false,
        },
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          strokeColor: "#fff",
          radius: 12,
        },
        itemMargin: {
          horizontal: 0,
          vertical: 4,
        },
        onItemClick: {
          toggleDataSeries: true,
        },
        onItemHover: {
          highlightDataSeries: true,
        },
      },
      labels: ["Private Cars", "Motorcycle", "Truck"],
    },
  },
  treemap: {
    options: {
      annotations: {},
      title: {
        text: "3. Treemap Chart",
        align: "left",
        margin: 4,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "18px",
          fontWeight: "bold",
          fontFamily: "Nunito Sans",
          color: "#333333",
        },
      },
      chart: {
        animations: {
          enabled: false,
        },
        background: "",
        foreColor: "#333",
        fontFamily: "Roboto",
        height: 320,
        id: "h66Z6",
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          autoSelected: "zoom",
        },
        type: "treemap",
        width: 471,
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          borderRadiusApplication: "end",
          borderRadiusWhenStacked: "last",
          hideZeroBarsWhenGrouped: false,
          isDumbbell: false,
          isFunnel: false,
          isFunnel3d: true,
          dataLabels: {
            total: {
              enabled: false,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: "#373d3f",
                fontSize: "12px",
                fontWeight: 600,
              },
            },
          },
        },
        bubble: {
          zScaling: true,
        },
        treemap: {
          dataLabels: {
            format: "scale",
          },
        },
        radialBar: {
          hollow: {
            background: "#fff",
          },
          dataLabels: {
            name: {},
            value: {},
            total: {},
          },
        },
        pie: {
          donut: {
            labels: {
              name: {},
              value: {},
              total: {},
            },
          },
        },
      },
      dataLabels: {
        style: {
          fontSize: 14,
          fontWeight: 700,
          colors: ["#fff"],
        },
      },
      grid: {
        show: false,
        padding: {
          right: 15,
          bottom: 20,
          left: 10,
        },
      },
      legend: {
        show: false,
        fontSize: 14,
        offsetY: 0,
        itemMargin: {
          vertical: 0,
        },
      },
      series: [
        {
          data: [
            {
              x: "Biology",
              y: "260",
              fillColor: "#2870FF",
            },
            {
              x: "Engineering",
              y: "200",
              fillColor: "#B2CCFF",
            },
            {
              x: "Math",
              y: "120",
              fillColor: "#538BFF",
            },
            {
              x: "Philosophy",
              y: "160",
              fillColor: "#0086C9",
            },
            {
              x: "HR",
              y: 31,
              fillColor: "#84ADFF",
            },
            {
              x: "Physics",
              y: 70,
              fillColor: "#37BFFA",
            },
            {
              x: "Comm",
              y: 30,
              fillColor: "#84ADFF",
            },
            {
              x: "MBA",
              y: 44,
              fillColor: "#0086C9",
            },
            {
              x: "PR",
              y: 68,
              fillColor: "#08A5EC",
            },
            {
              x: "English",
              y: 28,
              fillColor: "#0086C9",
            },
            {
              x: "History",
              y: 19,
              fillColor: "#08A5EC",
            },
            {
              x: "IT",
              y: 29,
              fillColor: "#00359E",
            },
            {
              x: "Edu",
              y: 10,
              fillColor: "#84ADFF",
            },
          ],
        },
      ],
      stroke: {
        colors: ["#fff"],
        fill: {
          type: "solid",
          opacity: 0.85,
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 100],
            colorStops: [],
          },
        },
      },
      tooltip: {
        followCursor: true,
        x: {
          show: false,
        },
      },
      xaxis: {
        type: "numeric",
        labels: {
          trim: true,
          style: {},
        },
        group: {
          groups: [],
          style: {
            colors: [],
            fontSize: "12px",
            fontWeight: 400,
            cssClass: "",
          },
        },
        tickPlacement: "on",
        tickAmount: "dataPoints",
        title: {
          style: {
            fontWeight: 700,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        show: false,
        tickAmount: 5,
        labels: {
          style: {},
        },
        title: {
          style: {
            fontWeight: 700,
          },
        },
      },
      theme: {
        palette: "palette4",
      },
    },
  },
  heatmap: {
    options: {
      annotations: {},
      title: {
        text: "4. Heatmap Chart",
        align: "left",
        margin: 4,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "18px",
          fontWeight: "bold",
          fontFamily: "Nunito Sans",
          color: "#333333",
        },
      },
      chart: {
        animations: {
          enabled: false,
        },
        foreColor: "#333",
        fontFamily: "Roboto",
        height: 220,
        id: "mmOyW",
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          autoSelected: "zoom",
        },
        type: "heatmap",
        width: 480,
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          borderRadiusApplication: "end",
          borderRadiusWhenStacked: "last",
          hideZeroBarsWhenGrouped: false,
          isDumbbell: false,
          isFunnel: false,
          isFunnel3d: true,
          dataLabels: {
            total: {
              enabled: false,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: "#373d3f",
                fontSize: "12px",
                fontWeight: 600,
              },
            },
          },
        },
        bubble: {
          zScaling: true,
        },
        treemap: {
          dataLabels: {
            format: "scale",
          },
        },
        radialBar: {
          hollow: {
            background: "#fff",
          },
          dataLabels: {
            name: {},
            value: {},
            total: {},
          },
        },
        pie: {
          donut: {
            labels: {
              name: {},
              value: {},
              total: {},
            },
          },
        },
      },
      colors: [
        "#BAE1FE",
        "#68BEFF",
        "#D9EEFF",
        "#89CCFF",
        "#229EFC",
        "#57B5FC",
        "#78C4FC",
      ],
      dataLabels: {
        enabled: false,
        style: {
          fontWeight: 700,
        },
      },
      grid: {
        padding: {
          right: 25,
          left: 15,
        },
      },
      legend: {
        fontSize: 14,
        offsetY: 0,
        markers: {
          offsetY: 2,
          shape: "square",
          size: 10,
        },
        itemMargin: {
          vertical: 0,
        },
      },
      series: [
        {
          name: "Saturday",
          data: [
            {
              x: "w1",
              y: "90",
            },
            {
              x: "w2",
              y: "40",
            },
            {
              x: "w3",
              y: "100",
            },
            {
              x: "w4",
              y: "80",
            },
            {
              x: "w5",
              y: "70",
            },
            {
              x: "w7",
              y: "70",
            },
            {
              x: "w8",
              y: "70",
            },
            {
              x: "w10",
              y: 10,
            },
            {
              x: "w11",
              y: "60",
            },
            {
              x: "w12",
              y: "60",
            },
            {
              x: "w13",
              y: "30",
            },
            {
              x: "w14",
              y: "30",
            },
          ],
        },
        {
          name: "Friday",
          data: [
            {
              x: "w1",
              y: "90",
            },
            {
              x: "w2",
              y: "40",
            },
            {
              x: "w3",
              y: "100",
            },
            {
              x: "w4",
              y: "80",
            },
            {
              x: "w5",
              y: "70",
            },
            {
              x: "w7",
              y: "70",
            },
            {
              x: "w8",
              y: "70",
            },
            {
              x: "w10",
              y: 10,
            },
            {
              x: "w11",
              y: "60",
            },
            {
              x: "w12",
              y: "60",
            },
            {
              x: "w13",
              y: "30",
            },
            {
              x: "w14",
              y: "30",
            },
          ],
        },
        {
          name: "Thursday",
          data: [
            {
              x: "w1",
              y: "90",
            },
            {
              x: "w2",
              y: "40",
            },
            {
              x: "w3",
              y: "100",
            },
            {
              x: "w4",
              y: "80",
            },
            {
              x: "w5",
              y: "70",
            },
            {
              x: "w7",
              y: "70",
            },
            {
              x: "w8",
              y: "70",
            },
            {
              x: "w10",
              y: 10,
            },
            {
              x: "w11",
              y: "60",
            },
            {
              x: "w12",
              y: "60",
            },
            {
              x: "w13",
              y: "30",
            },
            {
              x: "w14",
              y: "30",
            },
          ],
        },
        {
          name: "Wednesday",
          data: [
            {
              x: "w1",
              y: "90",
            },
            {
              x: "w2",
              y: 17,
            },
            {
              x: "w3",
              y: "100",
            },
            {
              x: "w4",
              y: "80",
            },
            {
              x: "w5",
              y: "70",
            },
            {
              x: "w7",
              y: "60",
            },
            {
              x: "w8",
              y: "70",
            },
            {
              x: "w10",
              y: 10,
            },
            {
              x: "w11",
              y: "60",
            },
            {
              x: "w12",
              y: "60",
            },
            {
              x: "w13",
              y: "30",
            },
            {
              x: "w14",
              y: "30",
            },
          ],
        },
        {
          name: "Tuesday",
          data: [
            {
              x: "w1",
              y: "90",
            },
            {
              x: "w2",
              y: "40",
            },
            {
              x: "w3",
              y: "100",
            },
            {
              x: "w4",
              y: "80",
            },
            {
              x: "w5",
              y: "70",
            },
            {
              x: "w7",
              y: "70",
            },
            {
              x: "w8",
              y: "70",
            },
            {
              x: "w10",
              y: 10,
            },
            {
              x: "w11",
              y: "60",
            },
            {
              x: "w12",
              y: "60",
            },
            {
              x: "w13",
              y: "30",
            },
            {
              x: "w14",
              y: "30",
            },
          ],
        },
        {
          name: "Monday",
          data: [
            {
              x: "w1",
              y: "90",
            },
            {
              x: "w2",
              y: "40",
            },
            {
              x: "w3",
              y: "100",
            },
            {
              x: "w4",
              y: "80",
            },
            {
              x: "w5",
              y: "70",
            },
            {
              x: "w7",
              y: "70",
            },
            {
              x: "w8",
              y: "70",
            },
            {
              x: "w10",
              y: 10,
            },
            {
              x: "w11",
              y: "60",
            },
            {
              x: "w12",
              y: "60",
            },
            {
              x: "w13",
              y: "30",
            },
            {
              x: "w14",
              y: "30",
            },
          ],
        },
        {
          name: "Sunday",
          data: [
            {
              x: "w1",
              y: "90",
            },
            {
              x: "w2",
              y: "40",
            },
            {
              x: "w3",
              y: "100",
            },
            {
              x: "w4",
              y: "80",
            },
            {
              x: "w5",
              y: "70",
            },
            {
              x: "w7",
              y: "70",
            },
            {
              x: "w8",
              y: "70",
            },
            {
              x: "w10",
              y: 10,
            },
            {
              x: "w11",
              y: "60",
            },
            {
              x: "w12",
              y: "60",
            },
            {
              x: "w13",
              y: "30",
            },
            {
              x: "w14",
              y: "30",
            },
          ],
        },
      ],
      stroke: {
        colors: ["#fff"],
        fill: {
          type: "solid",
          opacity: 0.85,
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 100],
            colorStops: [],
          },
        },
      },
      tooltip: {
        followCursor: true,
        x: {
          show: false,
        },
        marker: {
          show: false,
        },
      },
      xaxis: {
        labels: {
          trim: true,
          style: {},
        },
        group: {
          groups: [],
          style: {
            colors: [],
            fontSize: "12px",
            fontWeight: 400,
            cssClass: "",
          },
        },
        tickPlacement: "on",
        title: {
          style: {
            fontWeight: 700,
          },
        },
      },
      yaxis: {
        labels: {
          offsetY: -10.882000000000001,
          style: {},
        },
        title: {
          style: {
            fontWeight: 700,
          },
        },
      },
      theme: {
        palette: "palette3",
      },
    },
  },
  stackedColumn: {
    options: {
      annotations: {},
      title: {
        text: "5. Stacked Column Chart",
        align: "left",
        margin: 4,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "18px",
          fontWeight: "bold",
          fontFamily: "Nunito Sans",
          color: "#333333",
        },
      },
      chart: {
        animations: {
          enabled: false,
          easing: "swing",
        },
        foreColor: "#333",
        fontFamily: "Nunito Sans",
        height: 230,
        id: "qTwbe",
        stacked: true,
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          autoSelected: "zoom",
        },
        type: "bar",
        width: 400,
        fontUrl: null,
      },
      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: "end",
          borderRadiusWhenStacked: "last",
          hideZeroBarsWhenGrouped: false,
          isDumbbell: false,
          isFunnel: false,
          isFunnel3d: true,
          dataLabels: {
            total: {
              enabled: false,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: "#373d3f",
                fontSize: "12px",
                fontWeight: 600,
              },
            },
          },
        },
        bubble: {
          zScaling: true,
        },
        treemap: {
          dataLabels: {
            format: "scale",
          },
        },
        radialBar: {
          hollow: {
            background: "#fff",
          },
          dataLabels: {
            name: {},
            value: {},
            total: {},
          },
        },
        pie: {
          donut: {
            labels: {
              name: {},
              value: {},
              total: {},
            },
          },
        },
      },
      colors: ["#12B76A", "#F79009", "#2970FF", "#F04438", "#546E7A"],
      dataLabels: {
        enabled: false,
        style: {
          fontWeight: 700,
        },
      },
      fill: {
        opacity: 1,
      },
      grid: {
        padding: {
          right: 25,
          left: 15,
        },
      },
      legend: {
        offsetY: 0,
        markers: {
          shape: "square",
          size: 8,
        },
        itemMargin: {
          horizontal: 12,
          vertical: 8,
        },
      },
      series: [
        {
          name: "Column 1",
          data: [
            {
              x: "Jan",
              y: 10,
            },
            {
              x: "Feb",
              y: 20,
            },
            {
              x: "Mar",
              y: 30,
            },
            {
              x: "Apr",
              y: "20",
            },
            {
              x: "May",
              y: 10,
            },
            {
              x: "Jun",
              y: 10,
            },
          ],
        },
        {
          name: "Column 2",
          data: [
            {
              x: "Jan",
              y: 15,
            },
            {
              x: "Feb",
              y: 10,
            },
            {
              x: "Mar",
              y: 25,
            },
            {
              x: "Apr",
              y: 30,
            },
            {
              x: "May",
              y: 10,
            },
            {
              x: "Jun",
              y: "15",
            },
          ],
        },
        {
          name: "series-3",
          data: [
            {
              x: "Jan",
              y: "10",
            },
            {
              x: "Feb",
              y: "20",
            },
            {
              x: "Mar",
              y: "15",
            },
            {
              x: "Apr",
              y: "20",
            },
            {
              x: "May",
              y: "15",
            },
            {
              x: "Jun",
              y: "5",
            },
          ],
        },
        {
          name: "series-4",
          data: [
            {
              x: "Jan",
              y: "5",
            },
            {
              x: "Feb",
              y: "10",
            },
            {
              x: "Mar",
              y: "20",
            },
            {
              x: "Apr",
              y: "10",
            },
            {
              x: "May",
              y: "20",
            },
            {
              x: "Jun",
              y: "5",
            },
          ],
        },
      ],
      stroke: {
        width: 0,
        fill: {
          type: "solid",
          opacity: 0.85,
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 100],
            colorStops: [],
          },
        },
      },
      tooltip: {
        shared: false,
        intersect: true,
      },
      xaxis: {
        labels: {
          trim: true,
          style: {},
        },
        group: {
          groups: [],
          style: {
            colors: [],
            fontSize: "12px",
            fontWeight: 400,
            cssClass: "",
          },
        },
        tickPlacement: "on",
        title: {
          style: {
            fontWeight: 700,
          },
        },
        tooltip: {
          enabled: false,
        },
      },
      yaxis: {
        labels: {
          style: {},
        },
        title: {
          style: {
            fontWeight: 700,
          },
        },
      },
      theme: {
        palette: "palette4",
      },
    },
  },
  stackedArea: {
    options: {
      annotations: {},
      title: {
        text: "6. Stacked Area Chart",
        align: "left",
        margin: 4,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "18px",
          fontWeight: "bold",
          fontFamily: "Nunito Sans",
          color: "#333333",
        },
      },
      chart: {
        animations: {
          enabled: false,
        },
        foreColor: "#333",
        fontFamily: "Nunito Sans",
        height: 229,
        id: "vLkx7",
        stacked: true,
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          autoSelected: "zoom",
        },
        type: "area",
        width: 436,
        fontUrl: null,
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          borderRadiusApplication: "end",
          borderRadiusWhenStacked: "last",
          hideZeroBarsWhenGrouped: false,
          isDumbbell: false,
          isFunnel: false,
          isFunnel3d: true,
          dataLabels: {
            total: {
              enabled: false,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: "#373d3f",
                fontSize: "12px",
                fontWeight: 600,
              },
            },
          },
        },
        bubble: {
          zScaling: true,
        },
        treemap: {
          dataLabels: {
            format: "scale",
          },
        },
        radialBar: {
          hollow: {
            background: "#fff",
          },
          dataLabels: {
            name: {},
            value: {},
            total: {},
          },
        },
        pie: {
          donut: {
            labels: {
              name: {},
              value: {},
              total: {},
            },
          },
        },
      },
      colors: ["#FDB022", "#32D583", "#32D583", "#fd6a6a", "#546E7A"],
      dataLabels: {
        enabled: false,
        style: {
          fontWeight: 700,
        },
      },
      fill: {
        type: "gradient",
        opacity: 1,
        gradient: {
          shade: "light",
          type: "vertical",
          inverseColors: false,
          opacityFrom: 0.8,
          opacityTo: 0.5,
        },
      },
      grid: {
        padding: {
          right: 36,
          left: 15,
        },
      },
      legend: {
        offsetY: 0,
        itemMargin: {
          horizontal: 12,
          vertical: 8,
        },
      },
      markers: {
        hover: {
          sizeOffset: 6,
        },
      },
      series: [
        {
          name: "Overload",
          data: [
            {
              x: "Jan",
              y: 20,
            },
            {
              x: "Feb",
              y: 32,
            },
            {
              x: "Mar",
              y: 38,
            },
            {
              x: "Apr",
              y: 22,
            },
            {
              x: "May",
              y: 56,
            },
            {
              x: "Jun",
              y: "55",
            },
            {
              x: "Jul",
              y: "65",
            },
          ],
        },
        {
          name: "Fit",
          data: [
            {
              x: "Jan",
              y: "35",
            },
            {
              x: "Feb",
              y: "40",
            },
            {
              x: "Mar",
              y: "30",
            },
            {
              x: "Apr",
              y: "50",
            },
            {
              x: "May",
              y: "60",
            },
            {
              x: "Jun",
              y: "65",
            },
            {
              x: "Jul",
              y: "70",
            },
          ],
        },
      ],
      stroke: {
        curve: "straight",
        lineCap: "round",
        width: 3,
        fill: {
          type: "solid",
          opacity: 0.85,
          gradient: {
            shade: "light",
            type: "vertical",
            shadeIntensity: 0.5,
            inverseColors: false,
            opacityFrom: 0.65,
            opacityTo: 0.5,
            stops: [0, 100, 100],
            colorStops: [],
          },
        },
      },
      xaxis: {
        labels: {
          trim: true,
          style: {},
        },
        group: {
          groups: [],
          style: {
            colors: [],
            fontSize: "12px",
            fontWeight: 400,
            cssClass: "",
          },
        },
        tickPlacement: "on",
        tickAmount: "dataPoints",
        title: {
          style: {
            fontWeight: 700,
          },
        },
      },
      yaxis: {
        labels: {
          style: {},
        },
        title: {
          style: {
            fontWeight: 700,
          },
        },
      },
      theme: {
        palette: "palette4",
      },
    },
  },
  scatter: {
    options: {
      annotations: {},
      title: {
        text: "1. Scatter Chart",
        align: "left",
        margin: 4,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "18px",
          fontWeight: "bold",
          fontFamily: "Nunito Sans",
          color: "#333333",
        },
      },
      chart: {
        animations: {
          enabled: false,
          dynamicAnimation: {
            enabled: false,
          },
        },
        foreColor: "#333",
        fontFamily: "Nunito Sans",
        height: 251,
        id: "JwokJ",
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          autoSelected: "zoom",
        },
        type: "scatter",
        width: 422,
        fontUrl: null,
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          borderRadiusApplication: "end",
          borderRadiusWhenStacked: "last",
          hideZeroBarsWhenGrouped: false,
          isDumbbell: false,
          isFunnel: false,
          isFunnel3d: true,
          dataLabels: {
            total: {
              enabled: false,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: "#373d3f",
                fontSize: "12px",
                fontWeight: 600,
              },
            },
          },
        },
        bubble: {
          zScaling: true,
        },
        treemap: {
          dataLabels: {
            format: "scale",
          },
        },
        radialBar: {
          hollow: {
            background: "#fff",
          },
          dataLabels: {
            name: {},
            value: {},
            total: {},
          },
        },
        pie: {
          donut: {
            labels: {
              name: {},
              value: {},
              total: {},
            },
          },
        },
      },
      colors: ["#2970FF", "#12B76A", "#F79009", "#fd6a6a", "#546E7A"],
      dataLabels: {
        enabled: false,
        style: {
          fontWeight: 700,
        },
      },
      fill: {
        opacity: 1,
      },
      grid: {
        padding: {
          right: 25,
          left: 15,
        },
      },
      legend: {
        offsetY: 0,
        itemMargin: {
          horizontal: 12,
          vertical: 8,
        },
      },
      markers: {
        size: 7,
        hover: {
          size: 0,
          sizeOffset: 2,
        },
      },
      series: [
        {
          name: "Depok",
          data: [
            {
              x: "1 Jan",
              y: 46,
            },
            {
              x: "2 Jan",
              y: 27,
            },
            {
              x: "3 Jan",
              y: 50,
            },
            {
              x: "4 Jan",
              y: "30",
            },
            {
              x: "5 Jan",
              y: "30",
            },
            {
              x: "6 Jan",
              y: 43,
            },
            {
              x: "7 Jan",
              y: 33,
            },
            {
              x: "8 Jan",
              y: 60,
            },
            {
              x: "9 Jan",
              y: 13,
            },
          ],
        },
        {
          name: "Bogor",
          data: [
            {
              x: "1 Jan",
              y: "40",
            },
            {
              x: "2 Jan",
              y: "50",
            },
            {
              x: "3 Jan",
              y: "60",
            },
            {
              x: "4 Jan",
              y: "50",
            },
            {
              x: "5 Jan",
              y: "70",
            },
            {
              x: "6 Jan",
              y: "50",
            },
            {
              x: "7 Jan",
              y: "60",
            },
            {
              x: "8 Jan",
              y: "50",
            },
            {
              x: "9 Jan",
              y: "70",
            },
          ],
        },
        {
          name: "Bekasi",
          data: [
            {
              x: "1 Jan",
              y: "70",
            },
            {
              x: "2 Jan",
              y: "60",
            },
            {
              x: "3 Jan",
              y: "50",
            },
            {
              x: "4 Jan",
              y: "40",
            },
            {
              x: "5 Jan",
              y: "50",
            },
            {
              x: "6 Jan",
              y: "60",
            },
            {
              x: "7 Jan",
              y: "40",
            },
            {
              x: "8 Jan",
              y: "70",
            },
            {
              x: "9 Jan",
              y: "60",
            },
          ],
        },
      ],
      stroke: {
        show: false,
        width: 0,
        fill: {
          type: "solid",
          opacity: 0.85,
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 100],
            colorStops: [],
          },
        },
      },
      tooltip: {
        shared: false,
        intersect: true,
      },
      xaxis: {
        labels: {
          trim: true,
          style: {},
        },
        group: {
          groups: [],
          style: {
            colors: [],
            fontSize: "12px",
            fontWeight: 400,
            cssClass: "",
          },
        },
        tickPlacement: "on",
        tickAmount: 12,
        title: {
          style: {
            fontWeight: 700,
          },
        },
      },
      yaxis: {
        labels: {
          style: {},
        },
        title: {
          style: {
            fontWeight: 700,
          },
        },
      },
      theme: {
        palette: "palette4",
      },
    },
  },
  lineScatter: {
    options: {
      title: {
        text: "2. Line Scatter Chart",
        align: "left",
        margin: 4,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "18px",
          fontWeight: "bold",
          fontFamily: "Nunito Sans",
          color: "#333333",
        },
      },
      series: [
        {
          name: "Points",
          type: "scatter",
          data: [
            {
              x: 1,
              y: 2.14,
            },
            {
              x: 1.2,
              y: 2.19,
            },
            {
              x: 1.8,
              y: 2.43,
            },
            {
              x: 2.3,
              y: 3.8,
            },
            {
              x: 2.6,
              y: 4.14,
            },
            {
              x: 2.9,
              y: 5.4,
            },
            {
              x: 3.2,
              y: 5.8,
            },
            {
              x: 3.8,
              y: 6.04,
            },
            {
              x: 4.55,
              y: 6.77,
            },
            {
              x: 4.9,
              y: 8.1,
            },
            {
              x: 5.1,
              y: 9.4,
            },
            {
              x: 7.1,
              y: 7.14,
            },
            {
              x: 9.18,
              y: 8.4,
            },
          ],
        },
        {
          name: "Line",
          type: "line",
          data: [
            {
              x: 1,
              y: 2,
            },
            {
              x: 2,
              y: 3,
            },
            {
              x: 3,
              y: 4,
            },
            {
              x: 4,
              y: 5,
            },
            {
              x: 5,
              y: 6,
            },
            {
              x: 6,
              y: 7,
            },
            {
              x: 7,
              y: 8,
            },
            {
              x: 8,
              y: 9,
            },
            {
              x: 9,
              y: 10,
            },
            {
              x: 10,
              y: 11,
            },
          ],
        },
      ],
      chart: {
        height: 350,
        type: "line",
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          autoSelected: "zoom",
        },
      },
      fill: {
        type: "solid",
      },
      markers: {
        size: [6, 0],
      },
      tooltip: {
        shared: false,
        intersect: true,
      },
      legend: {
        show: false,
      },
      yaxis: {
        title: {
          text: "Conversion Rate",
        },
      },
      xaxis: {
        title: {
          text: "CTR",
        },
        type: "numeric",
        min: 0,
        max: 12,
        tickAmount: 12,
      },
    },
    series: [
      {
        name: "Series 1",
        type: "line", // Use 'line' type for the line part of the chart
        data: [
          {
            x: 10,
            y: 20,
          },
          {
            x: 20,
            y: 35,
          },
          {
            x: 30,
            y: 10,
          },
          {
            x: 40,
            y: 55,
          },
          {
            x: 50,
            y: 45,
          },
        ],
      },
      {
        name: "Series 2",
        type: "scatter", // Use 'scatter' type for the scatter points
        data: [
          {
            x: 15,
            y: 25,
          },
          {
            x: 25,
            y: 40,
          },
          {
            x: 35,
            y: 15,
          },
          {
            x: 45,
            y: 60,
          },
          {
            x: 55,
            y: 50,
          },
        ],
      },
    ],
  },
  bubble: {
    options: {
      annotations: {},
      title: {
        text: "3. Bubble Chart",
        align: "left",
        margin: 4,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "18px",
          fontWeight: "bold",
          fontFamily: "Nunito Sans",
          color: "#333333",
        },
      },
      chart: {
        animations: {
          enabled: false,
          dynamicAnimation: {
            enabled: false,
          },
        },
        foreColor: "#333",
        fontFamily: "Roboto",
        height: 229,
        id: "p8irD",
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          autoSelected: "zoom",
        },
        type: "bubble",
        width: 431,
      },
      plotOptions: {
        bar: {
          borderRadius: 10,
          borderRadiusApplication: "end",
          borderRadiusWhenStacked: "last",
          hideZeroBarsWhenGrouped: false,
          isDumbbell: false,
          isFunnel: false,
          isFunnel3d: true,
          dataLabels: {
            total: {
              enabled: false,
              offsetX: 0,
              offsetY: 0,
              style: {
                color: "#373d3f",
                fontSize: "12px",
                fontWeight: 600,
              },
            },
          },
        },
        bubble: {
          zScaling: true,
        },
        treemap: {
          dataLabels: {
            format: "scale",
          },
        },
        radialBar: {
          hollow: {
            background: "#fff",
          },
          dataLabels: {
            name: {},
            value: {},
            total: {},
          },
        },
        pie: {
          donut: {
            labels: {
              name: {},
              value: {},
              total: {},
            },
          },
        },
      },
      colors: ["#528bff", "#32d583", "#fcb022"],
      dataLabels: {
        enabled: false,
        style: {
          fontWeight: 700,
        },
      },
      fill: {
        opacity: 1,
        gradient: {
          inverse: true,
        },
      },
      grid: {
        padding: {
          right: 25,
          left: 15,
        },
      },
      legend: {
        fontSize: 14,
        offsetY: 0,
        itemMargin: {
          vertical: 0,
        },
      },
      markers: {
        hover: {
          size: 0,
        },
      },
      series: [
        {
          name: "Designer",
          data: [
            {
              x: "0",
              y: "32",
              z: "17",
            },
            {
              x: "10",
              y: 78,
              z: "40",
            },
            {
              x: "20",
              y: 14,
              z: "14",
            },
            {
              x: "30",
              y: 67,
              z: "48",
            },
            {
              x: "40",
              y: 15,
              z: 29,
            },
            {
              x: "50",
              y: 12,
              z: "20",
            },
            {
              x: "60",
              y: "53",
              z: "10",
            },
            {
              x: "70",
              y: "45",
              z: "20",
            },
            {
              x: "80",
              y: "21",
              z: "36",
            },
          ],
        },
        {
          name: "Engineer",
          data: [
            {
              x: "0",
              y: "20",
              z: "30",
            },
            {
              x: "10",
              y: 48,
              z: "20",
            },
            {
              x: "20",
              y: 22,
              z: "30",
            },
            {
              x: "30",
              y: 29,
              z: "40",
            },
            {
              x: "40",
              y: 15,
              z: 29,
            },
            {
              x: "50",
              y: 12,
              z: "20",
            },
            {
              x: "60",
              y: "20",
              z: "16",
            },
            {
              x: "70",
              y: "15",
              z: 20,
            },
            {
              x: "80",
              y: "30",
              z: "10",
            },
            {
              x: "10",
              y: "45",
              z: "20",
            },
          ],
        },
        {
          name: "Product Owner",
          data: [
            {
              x: "38",
              y: "24",
              z: "30",
            },
            {
              x: "12",
              y: 48,
              z: "20",
            },
            {
              x: "22",
              y: 14,
              z: "14",
            },
            {
              x: "10",
              y: 40,
              z: "10",
            },
            {
              x: "20",
              y: 15,
              z: 29,
            },
            {
              x: "40",
              y: 12,
              z: "20",
            },
            {
              x: "80",
              y: "30",
              z: "10",
            },
            {
              x: "20",
              y: "45",
              z: "20",
            },
          ],
        },
      ],
      stroke: {
        show: false,
        fill: {
          type: "solid",
          opacity: 0.85,
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 100],
            colorStops: [],
          },
        },
      },
      tooltip: {
        shared: false,
        intersect: true,
      },
      xaxis: {
        labels: {
          trim: true,
          style: {},
        },
        group: {
          groups: [],
          style: {
            colors: ["#32d583", "#fcb022"],
            fontSize: "12px",
            fontWeight: 400,
            cssClass: "",
          },
        },
        tickPlacement: "on",
        tickAmount: "dataPoints",
        title: {
          text: "Number of Tasks",
          style: {
            fontWeight: 700,
          },
        },
      },
      yaxis: {
        labels: {
          style: {},
        },
        title: {
          text: "Number of Workers",
          style: {
            fontWeight: 700,
          },
        },
      },
      theme: {
        palette: "palette4",
      },
    },
  },
  histogram: {
    options: {
      series: [
        {
          name: "sales",
          data: [
            {
              x: "Jan",
              y: 400,
            },
            {
              x: "Feb",
              y: 430,
            },
            {
              x: "March",
              y: 448,
            },
            {
              x: "April",
              y: 470,
            },
            {
              x: "May",
              y: 540,
            },
            {
              x: "Jun",
              y: 580,
            },
            {
              x: "Jul",
              y: 690,
            },
            {
              x: "Aug",
              y: 690,
            },
          ],
        },
      ],
      chart: {
        type: "bar",
        height: 380,
      },
      yaxis: {
        title: {
          text: "Number of Customers",
        },
      },
      xaxis: {
        type: "category",
        group: {
          style: {
            fontSize: "10px",
            fontWeight: 700,
          },
          groups: [
            { title: "2021", cols: 4 },
            { title: "2022", cols: 4 },
          ],
        },
      },
      title: {
        text: "2. Histogram Chart",
      },
    },
    series: [
      {
        name: "Histogram",
        data: [
          { x: "0 - 25", y: 5 },
          { x: "25 - 50", y: 8 },
          { x: "50 - 75", y: 12 },
          { x: "75 - 100", y: 6 },
          { x: "100 - 125", y: 10 },
          { x: "125 - 150", y: 15 },
          { x: "150 - 175", y: 9 },
          { x: "175 - 200", y: 4 },
        ],
      },
    ],
  },
  boxPlot: {
    options: {
      title: {
        text: "1. Box Plot Chart",
        align: "left",
        margin: 4,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "18px",
          fontWeight: "bold",
          fontFamily: "Nunito Sans",
          color: "#333333",
        },
      },
      chart: {
        id: "box-plot-chart",
        height: 350,
        toolbar: {
          show: true,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: true,
            selection: true,
            zoom: true,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true,
            customIcons: [],
          },
          autoSelected: "zoom",
        },
      },
      xaxis: {
        tickPlacement: "on",
        categories: [
          "Category 1",
          "Category 2",
          "Category 3",
          "Category 4",
          "Category 5",
        ],
        title: {
          text: "Categories",
        },
      },
      yaxis: {
        title: {
          text: "Values",
        },
      },
      plotOptions: {
        boxPlot: {
          colors: {
            lower: "#c3b4fd",
            median: "#ffbc00",
            upper: "#7839ee",
          },
        },
      },
    },
    series: [
      {
        name: "Data",
        data: [
          {
            x: "Category 1",
            y: [14, 35, 47, 55, 60, 70, 85],
          },
          {
            x: "Category 2",
            y: [30, 35, 40, 45, 50, 55, 60],
          },
          {
            x: "Category 3",
            y: [20, 35, 45, 50, 55, 65, 80],
          },
          {
            x: "Category 4",
            y: [10, 25, 35, 40, 45, 55, 70],
          },
          {
            x: "Category 5",
            y: [5, 20, 30, 35, 40, 50, 65],
          },
        ],
      },
    ],
  },
};

import React, { useState, useRef, useContext } from "react";
import IframeResizer from "iframe-resizer-react";

import { themeContext } from "../../provider";

const StorybookEmbed = ({ storybookId, withArgs = false }) => {
  const globalThemeContext = useContext(themeContext);
  const iframeRef = useRef(null);

  const [isIframeLoaded, setIsIframeLoaded] = useState(true);
  const [messageData, setMessageData] = useState();

  const onResized = (data) => setMessageData(data);

  const onMessage = (data) => {
    setMessageData(data);
    iframeRef.current.sendMessage("Hello back from the parent page");
  };

  const handleOnLoad = () => {
    setIsIframeLoaded(false);
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: isIframeLoaded ? "flex" : "none",
          alignItems: "center",
          justifyContent: "center",
          paddingTop: "2rem",
        }}
      >
        {/* <Spinner /> */}
        tunggu
      </div>
      <div
        style={{
          height: "max-content",
          width: "100%",
          // marginLeft: '-20px',
          // marginTop: '-32px',
        }}
      >
        <IframeResizer
          bodyPadding="0px"
          log
          inPageLinks
          forwardRef={iframeRef}
          onMessage={onMessage}
          onResized={onResized}
          onLoad={handleOnLoad}
          autoResize
          heightCalculationMethod="lowestElement"
          style={{ width: "1px", minWidth: "100%" }}
          src={`https://master--6420e4161e2acdd49d1d3e4b.chromatic.com/iframe.html?id=${storybookId}&viewMode=story&shortcuts=false&singleStory=true${
            withArgs && "&args=color:primary500"
          }&globals=theme:${globalThemeContext?.currentTheme ?? "Legion"};`}
        />
      </div>
    </>
  );
};

export default StorybookEmbed;

import { Text } from "@legion-ui/core";
import FontFamily from "../../../../src/components/FontFamily.jsx";
import StorybookEmbed from "../../../../src/components/StorybookEmbed.jsx";
import StorybookEmbedTypography from "../../../../src/components/StorybookEmbedTypography.jsx";
import * as React from 'react';
export default {
  Text,
  FontFamily,
  StorybookEmbed,
  StorybookEmbedTypography,
  React
};